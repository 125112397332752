// Usage:
// <element x-data="animate" x-bind="blurUp" />
document.addEventListener('alpine:init', () => {
    Alpine.data('animate', (delay = 0) => ({
        shown: false,
        delay: delay,

        blurUp: prepareAnimation('slide-in-blurred-bottom'),
        fadeUp: prepareAnimation('slide-in-bottom'),
        fadeUpSlow: prepareAnimation('slide-in-bottom-slow'),
        fadeIn: prepareAnimation('fade-in'),
        bounceUp: prepareAnimation('bounce-in-bottom'),
        bounceIn: prepareAnimation('bounce-in-fwd'),
        bounceInFromFront: prepareAnimation('bounce-in-bck'),
        bounceInFromRight: prepareAnimation('bounce-in-right'),
    }))
})

function prepareAnimation(className) {
    return {
        ['x-intersect.threshold.05.once']() {
            if (this.delay !== 0 && window.innerWidth > 640) {
                setTimeout(() => {
                    this.shown = true
                }, this.delay)
            } else {
                this.shown = true
            }
        },
        [':style']: '!shown && {visibility: \'hidden\'}',
        [':class']: 'shown && \'' + className + '\'',
    }
}

document.addEventListener('alpine:init', () => {
    Alpine.data('button', () => ({
        setStyle: function (relY, relX, height, width) {
            this.$refs.span.style.top = relY
            this.$refs.span.style.left = relX
            this.$refs.span.style.height = height
            this.$refs.span.style.width = width
        },
        button: {
            ['@mouseenter'](e) {
                if (window.innerWidth < 640) return

                let parentOffset = this.$el.getBoundingClientRect(),
                    relX = e.pageX - (parentOffset.left + window.scrollX),
                    relY = e.pageY - (parentOffset.top + window.scrollY),
                    width = parentOffset.width * 2.25
                // NB: it is correct to pass the width var to the height parameter
                this.setStyle(relY + 'px', relX + 'px', width + 'px', '225%')
            },
            ['@mouseout'](e) {
                if (window.innerWidth < 640) return

                let parentOffset = this.$el.getBoundingClientRect(),
                    relX = e.pageX - (parentOffset.left + window.scrollX),
                    relY = e.pageY - (parentOffset.top + window.scrollY)
                this.setStyle(relY + 'px', relX + 'px', '0px', '0px')
            }
        }
    }))
})